import { ErrorMessage, Field, useFormikContext} from "formik";
import { FunctionComponent, useContext, useState } from "react";
import { AdminContext, CloudStorage } from "../../types";
import { StepConfig, useWizard } from "react-formik-step-wizard";
import * as Yup from 'yup';
import { Button,  Image, Collapse } from "react-bootstrap";
import { Icon } from "@fluentui/react";

interface OtherParametersProps {
    initialItem: CloudStorage
}

const MAX_MaxDurationMinutes = 4320;

const OtherParametersStep : FunctionComponent<OtherParametersProps> = ({initialItem}) => {
    const canAdmin = useContext(AdminContext);
    const { values } = useFormikContext(); // valeur de l'étape actuelle, ni des précédentes/suivantes    
    const previousSteps = useWizard() ; // valeurs des étapes précédentes
    const kind = previousSteps.values.Platform.kind;    
    const [helpOpen, setHelpOpen] = useState(false);
    const [helpImg, setHelpImg] = useState("");
    const [helpImg2, setHelpImg2] = useState("");
    const setHelp = (url:string, url2?:string) => {
        if( url !== helpImg){
            setHelpImg(url);
            if (url2)
                setHelpImg2(url2);
            else
                setHelpImg2('');
            setHelpOpen(true);
        } else {
            setHelpOpen(!helpOpen);
        }
    }

    return (
        <>
            <div className="form-group row mt-3">
                <label htmlFor="branch" className="col-3 col-form-label">Branch</label>
                <div className="col-7">
                    <Field as="select" className="form-control" name="branch">
                        <option value=""></option>
                        <option value="EP">Exploration Production (EP)</option>
                        <option value="MS">Marketing Services (MS)</option>
                        <option value="RC">Refineries Chemical (RC)</option>
                        <option value="GRP">Gas, Renewables & Power (GRP)</option>
                        <option value="TGS">Total Global Services (TGS)</option>
                        <option value="TS">Trading Shipping (TS)</option>
                    </Field>
                    <ErrorMessage name="confidentiality"  >{(msg : string) => <div className="invalid-feedback d-block">{msg}</div>}</ErrorMessage>
                </div>
            </div>
            <div>CMDB details</div>
            <div className="form-group row mt-3">
                <label htmlFor="cmdbBusinessService" className="col-3 col-form-label">Business Service Name</label>
                <div className="col-7">
                    <Field type="text" className="form-control" 
                        name="cmdbBusinessService" 
                        placeholder='AWS-********'
                        />
                    <ErrorMessage name="cmdbBusinessService"  >{(msg : string) => <div className="invalid-feedback d-block">{msg}</div>}</ErrorMessage>
                </div>
            </div>                          
            <div className="form-group row">
                <label htmlFor="cmdbApplicationService" className="col-3 col-form-label">Application Service Name</label>
                <div className="col-7">
                    <Field type="text" className="form-control" 
                        name="cmdbApplicationService" 
                        placeholder='AWS-********-D'
                        />
                    <ErrorMessage name="cmdbApplicationService"  >{(msg : string) => <div className="invalid-feedback d-block">{msg}</div>}</ErrorMessage>
                </div>
            </div>
            {!["AzureDataLakeTE", "AwsDataLakeTE"].includes(kind) &&
                <>
                    <div>Provide optional parameters</div>
                    <div className="form-group row mt-3">
                        <label htmlFor="maxDurationMinutes" className="col-3 col-form-label">Token Max Duration</label>
                        <div className="col-7">
                            <Field type="text" className={initialItem.maxDurationMinutes>MAX_MaxDurationMinutes && !canAdmin ? "form-control-plaintext" : "form-control"} 
                                name="maxDurationMinutes" 
                                placeholder="number of minutes"

                            />
                            {  
                                initialItem.maxDurationMinutes>MAX_MaxDurationMinutes && !canAdmin &&
                                <span className="text-danger">Due to a derogation, you cannot modify anymore this parameter</span>
                            }
                            { 
                                (values as any).maxDurationMinutes>MAX_MaxDurationMinutes && canAdmin && 
                                <span className="text-danger">Set a value more than {MAX_MaxDurationMinutes} only after a derogation from the Cyber Team</span>
                            }
                            <ErrorMessage name="maxDurationMinutes"  >{(msg : string) => <div className="invalid-feedback d-block">{msg}</div>}</ErrorMessage>
                        </div>
                        <div className="col-2">
                            <Button variant="secondary" 
                                onClick={() =>  setHelp("/MaxDuration.png")}
                                aria-expanded={helpOpen}
                                aria-controls="helpOpen"
                            > Explanation <Icon iconName='Help'/></Button>

                        </div>
                    </div> 
            <div className={"form-group row" + (kind?.startsWith("Azure") ? "": " d-none") } >
                <label htmlFor="logInfo" className="col-3 col-form-label">Application Insight Connection String</label>
                <div className="col-7">
                    <Field type="text" className="form-control" 
                        name="logInfo" 
                        placeholder="InstrumentationKey=<guid>;IngestionEndpoint=https://xxxx.in.applicationinsights.azure.com/;LiveEndpoint=https://xxxx.livediagnostics.monitor.azure.com/"
                        />
                    <ErrorMessage name="logInfo"  >{(msg : string) => <div className="invalid-feedback d-block">{msg}</div>}</ErrorMessage>
                </div>
                <div className="col-2">
                    <Button variant="secondary" 
                                onClick={() =>  setHelp("/" + kind + "LogInfo.png")}
                                aria-expanded={helpOpen}
                                aria-controls="helpOpen"
                                > How to find <Icon iconName='Help'/></Button>
                </div>
            </div> 
            <div className="mt-2" id="adminFormHelp">
                <Collapse in={helpOpen} >
                    <div id="helpOpen">
                        <Image src={helpImg} fluid />
                        {helpImg2!=="" &&
                            <div className="mt-2"> 
                                <Image src={helpImg2} fluid />
                            </div>
                        }
                    </div>
                </Collapse>           
            </div>   
            </>
            }             
        </>
    );
}


export function OtherParametersStepConfig(initialItem:CloudStorage  ) : StepConfig {
    const canAdmin = useContext(AdminContext);    
    return {
        id: 'OtherParameters',
        component: <OtherParametersStep initialItem={initialItem} />,
        initialValues: {
            maxDurationMinutes: initialItem.maxDurationMinutes,
            cmdbBusinessService: initialItem.cmdbBusinessService,
            cmdbApplicationService: initialItem.cmdbApplicationService,
            branch: initialItem.branch,
            logInfo: initialItem.logInfo ? initialItem.logInfo : ''
        },
        validationSchema: Yup.object({
            maxDurationMinutes: Yup.number().integer('a positive integer').required('required').positive('a positive integer'),
            branch: Yup.string().required('required').oneOf(["EP", "MS", "RC", "GRP", "TGS", "TS"]),
            cmdbBusinessService: Yup.string().required('required'),
            logInfo: Yup.string().nullable(),
        }),

        validate: (stepValues, allValues) => {
            const errors :any = {}
            const kind = allValues.Platform.kind;
            if (initialItem.maxDurationMinutes<=MAX_MaxDurationMinutes && !canAdmin ) {
                if (  stepValues.maxDurationMinutes > MAX_MaxDurationMinutes )
                    errors.maxDurationMinutes = "more than " +  MAX_MaxDurationMinutes + " minutes needs a derogation";
            }
            switch(kind) {
                case "AzureBlob":
                case "AzureFile":
                case "AwsS3":
                case "AzureDataLake":
                    if (stepValues.logInfo && !stepValues.logInfo.match(/^InstrumentationKey=[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12};.*/)) {
                        errors.logInfo = 'valid Application Insight connection string';
                    }
            }
            return errors;
        } ,
        disableNextOnErrors: true       
    }
}